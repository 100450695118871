import React from 'react';

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.toggleOffers = this.toggleOffers.bind(this);
    this.escFunction = this.escFunction.bind(this);
    if (typeof window !== 'undefined' && window.sessionStorage && sessionStorage.getItem('viewed-offers') === '1') {
      this.state = {
        offersOpen: false,
        pixel:false
      };
    } else {
      this.state = {
        offersOpen: true,
        pixel:false
      };
    }
  }
  toggleOffers(e) {
    this.setState({ offersOpen: !this.state.offersOpen });
  }
  escFunction(event){
    if(event.keyCode === 27) {
      this.setState({ offersOpen: false });
    }
  }
  componentDidMount(){
    if (typeof window !== 'undefined' && window.sessionStorage) {
      sessionStorage.setItem('viewed-offers', '1');
    }

    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  trackFb(){
    this.setState({pixel:true})
    if(typeof window !== "undefined") {
      window.fbq('track', 'Purchase')
    }
  }


  render() {
    if(!this.props.bypassHide){
      return <></>
    }

    return (
      <div className={ this.state.offersOpen ? "open triplesoft-coupon" : "triplesoft-coupon"}>
        <div className="triplesoft-coupon-tab" onClick={this.toggleOffers}>
          <div className="triplesoft-coupon-tab-title">
            SCOTTIES<sup>®</sup> OFFERS
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="16.894" height="25.906" viewBox="0 0 16.894 25.906">
            <path id="Fill_1" data-name="Fill 1" d="M4.327,25.751l12.42-12.425a.545.545,0,0,0,0-.746L4.327.155a.527.527,0,0,0-.746,0L.155,3.583a.527.527,0,0,0,0,.746l8.621,8.625L.155,21.577a.545.545,0,0,0,0,.747l3.427,3.427a.527.527,0,0,0,.746,0" fill="#fff"/>
          </svg>
        </div>
        <div className="triplesoft-coupon-wrapper">
          <div className="triplesoft-coupon-container">
            <div className="triplesoft-details-holder">
              <svg xmlns="http://www.w3.org/2000/svg" width="702" height="161" viewBox="0 0 442 161">
                <g id="copy" transform="translate(-535 -894)">
                  <g id="Group_58" data-name="Group 58" transform="translate(384.226 757.341)">
                    <text id="INTRODUCING_NEW" data-name="INTRODUCING NEW" transform="translate(345.774 152.659)" fill="#8d1b60" font-size="21" font-family="IntroBold, sans-serif" font-weight="700" letter-spacing="0.2em"><tspan x="-133.592" y="0">INTRODUCING NEW</tspan></text>
                    <g id="Group_27" data-name="Group 27" transform="translate(134.811 176.876)">
                      <text id="triple_soft_" data-name="triple soft™" transform="translate(214.963 49.783)" fill="#8d1b60" font-size="67" font-family="IntroBold, sans-serif" font-weight="700"><tspan x="-177.211" y="0">triple soft</tspan><tspan y="0" font-size="39.081" baseline-shift="22.331100265055603">®</tspan></text>
                      <circle id="Ellipse_1" data-name="Ellipse 1" cx="9" cy="9" r="9" transform="translate(-0.037 23.783)" fill="#8d1b60"/>
                      <circle id="Ellipse_2" data-name="Ellipse 2" cx="9" cy="9" r="9" transform="translate(399.963 23.783)" fill="#8d1b60"/>
                    </g>
                  </g>
                  <g id="Group_60" data-name="Group 60" transform="translate(-3718 -2302)">
                    <text id="layers_of" data-name="layers of" transform="translate(4193 3338)" fill="#8d1b60" font-size="25" font-family="IntroBold, sans-serif" font-weight="700"><tspan x="0" y="0">layers of</tspan></text>
                    <text id="softness" transform="translate(4308 3340)" fill="#cf9bbf" font-size="54" font-family="FancierScript, Fancier Script"><tspan x="0" y="0">softness</tspan></text>
                    <text id="you_can_feel_" data-name="you can feel™" transform="translate(4439 3338)" fill="#8d1b60" font-size="25" font-family="IntroBold, sans-serif" font-weight="700"><tspan x="0" y="0">for Ultimate Protection</tspan><tspan y="0" font-size="14.582" baseline-shift="8.332499716634633">™</tspan></text>
                    <text id="_3" data-name="3" transform="translate(4153 3340)" fill="#8d1b60" font-size="50" font-family="IntroBold, sans-serif" font-weight="700"><tspan x="0" y="0">3</tspan></text>
                    <path id="Desktop_Coupon_SoftnessCurve" d="M268.881,522.072a19.1,19.1,0,0,1,4.08-2.389,32.6,32.6,0,0,1,4.484-1.622,41.167,41.167,0,0,1,9.443-1.451,53.067,53.067,0,0,1,9.554.43l2.367.334,2.339.475,4.549.915a74.49,74.49,0,0,0,18.133,1.4,76.442,76.442,0,0,0,18.216-2.906,48.413,48.413,0,0,1-17.988,5.626,62.164,62.164,0,0,1-9.538.442,68.447,68.447,0,0,1-9.5-1.135l-4.591-1.008-2.23-.495-2.245-.384a65.7,65.7,0,0,0-9.051-.977,51,51,0,0,0-9.11.4,37.835,37.835,0,0,0-4.513.859A25,25,0,0,0,268.881,522.072Z" transform="translate(4080.934 2830.087)" fill="#cf9bbf"/>
                  </g>
                </g>
              </svg>
              <div className="triplesoft-coupon-box" />
            </div>
            <div className="triplesoft-coupon-holder">
              <a onClick={() => { this.trackFb() }} className="button blue-outline" target="_blank" href="../../tscoupon">Get Coupon</a>
            </div>
          </div>

        </div>
        {
          this.state.pixel ?
          <img src="//20831022p.rfihub.com/ca.gif?rb=43524&ca=20831022&ra=[%Random%]&_o=43524&_t=20831022" height="0" width="0" style={{'position':'absolute', visibility:'hidden',color: 'transparent'}} alt="DSP"/>:null
        }

      </div>
    )
  }
}

export default Offers;
