/* eslint-disable */
import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'

import sendEvent from '../helpers/sendAnalytics';

const EmailHomeSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required'),
  first_name: Yup.string()
    .required('First name is required'),
  last_name: Yup.string()
    .required('Last name is required'),
  terms: Yup.mixed()
    .oneOf([true], 'You must opt-in to continue')
});

class EmailHomeForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      isChecked: true
    };
  }

  handleInputChange(event) {
    this.setState({isChecked: event.target.checked});
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            email: '',
            first_name: '',
            last_name: '',
            terms: false
          }}
          validationSchema={EmailHomeSchema}
          onSubmit={values => {

            const restApi = `https://www.master-7rqtwti-ffol3tfn6cv7m.ca-1.platformsh.site/api/create/email?firstname=${values.first_name}&lastname=${values.last_name}&email=${values.email}&brand=scotties&language=en`
            const config = {
              headers: {
                'content-type': 'application/json',
                'accept': 'application/json'
              }
            };

            axios.get(restApi, config)
              .then((res) => {
                this.props.handlePageChange('thanks')
                sendEvent({
                  ga: {
                    category: 'Email_Subscribe',
                    action: 'Click',
                    label: 'Website_Email_Signup'
                  }
                })
              })
              .catch((error) => {
              this.props.handlePageChange('thanks')
            })
          }}
          render={({ errors, touched }) => (
            <Form className="home-email">
              {errors.first_name && touched.first_name && <div className="field-error display-block">{errors.first_name}</div>}
              {errors.last_name && touched.last_name && <div className="field-error display-block">{errors.last_name}</div>}
              <div className="name_inputs">
                <div className="first_name-input">
                  <label htmlFor="first_name" >First name</label>
                  <Field name="first_name" aria-labelledby="first_name" placeholder="First Name" type="text" id="first_name" className="text-field w-input" autocomplete="given-name"/>
                </div>
                <div className="last_name-input">
                  <label htmlFor="last_name" >Last name</label>
                  <Field name="last_name" id="last_name" aria-labelledby="last_name"  placeholder="Last Name" type="text" className="text-field w-input" autocomplete="family-name"/>
                </div>
              </div>
              {errors.email && touched.email && <div className="field-error">{errors.email}</div>}
              <div className="email-input">
                <label htmlFor="email">Email</label>
                <Field name="email" id="email" placeholder="Email Address" aria-labelledby="email" type="email" className="text-field w-input" autocomplete="email"/>
                <button type="submit" aria-label="submit" className="submit-button w-button"></button>
              </div>
              {errors.terms && touched.terms && <div className="field-error">{errors.terms}</div>}
              <div className="radio-button-field w-radio">
                <Field id="terms" name="terms" aria-labelledby="terms"  id="terms" component="input" type="checkbox" />
                <label htmlFor="terms" className="field-label show w-form-label cinline">Yes! I would like to subscribe to SCOTTIES<sup>®</sup> emails to be among the first to know about savings, news and rewards.</label>
              </div>
              {errors.signup && touched.signup && <div className="field-error">{errors.signup}</div>}
            </Form>
          )}
        />
      </div>
    )
  }
};

class ImageLeftTextRightCentered extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      page: 'email'
    };
  }

  handlePageChange(page) {
    this.setState({page, page});
  }

  render() {
    return (
      <div className="image-left-and-text-right-centered">
        <div className="image-left-and-text-right-centered-wrapper">

          <div className="image-left-and-text-right-centered-image">
            { (this.props.data.relationships.field_image && this.props.data.relationships.field_image.localFile) ?
              <img src={this.props.data.relationships.field_image.localFile.childImageSharp.sizes.src} alt="" />
            : null }
          </div>

          {(this.state.page === 'email') ?
          <div className="image-left-and-text-right-centered-text">
            <div className="image-left-text-right-centered-text-wrapper">
              <h2 className="section-title text-right-centered-title">{this.props.data.field_title}
              <span className="sub-title text-right-centered-sub-title">{this.props.data.field_sub_title}</span>
              </h2>

              <div className="section-body-text text-right-centered-body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>

              <EmailHomeForm handlePageChange={this.handlePageChange}/>

            </div>
          </div>
          : null }

          {(this.state.page === 'thanks') ?
          <div className="image-left-and-text-right-centered-text">
            <div className="image-left-text-right-centered-text-wrapper">

              <h2 className="section-title text-right-centered-title thanks">Thank you</h2>

              <div className="section-body-text text-right-centered-body-text thanks">for signing up</div>

            </div>
          </div>
          : null }

        </div>
        <div className="image-left-text-right-centered-background"></div>
      </div>
    )
  }
}

export default ImageLeftTextRightCentered
