/* eslint-disable */
import React from 'react';

import mapwest from './../layouts/images/map-west.png';
import mapmidwest from './../layouts/images/map-midwest.png';
import mapsoutheast from './../layouts/images/map-southeast.png';
import mapnortheast from './../layouts/images/map-northeast.png';
import mapmidatlantic from './../layouts/images/map-midatlantic.png';

import sendEvent from '../helpers/sendAnalytics';

class FindRetailer extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.ImageMap = this.ImageMap.bind(this);
    this.state = {
      locations: [
        'West',
        'Midwest',
        'Southeast',
        'Mid-Atlantic',
        'Northeast'
      ],
      location: 'West'
    };
  }
  handleChange(e,location) {
    e.preventDefault();
    this.setState({location: location});
    sendEvent({
      ga: {
        category: 'Product_Engagement',
        action: 'Find_Retailer_Map',
        label: location
      }
    })
  }
  ImageMap = function (selector) {
    var self = this;

    if (!self) { return new ImageMap(selector); }

    self.selector = selector instanceof Array ? selector : [].slice.call(document.querySelectorAll(selector));

    (self.update = function () {
        self.selector.forEach(function (val) {
            var img = val,
                newImg = document.createElement('img');

            if (typeof img.getAttribute('usemap') === 'undefined') { return; }

            newImg.addEventListener('load', function () {
                var clone = new Image();
                clone.src = img.getAttribute('src');

                var w = img.getAttribute('width') || clone.width,
                    h = img.getAttribute('height') || clone.height,
                    wPercent = img.offsetWidth / 100,
                    hPercent = img.offsetHeight / 100,
                    map = img.getAttribute('usemap').replace('#', ''),
                    c = 'coords';

                [].forEach.call(document.querySelectorAll('map[name="' + map + '"] area'), function (val) {
                    var area = val,
                        coordsS = area.dataset[c] = area.dataset[c] || area.getAttribute(c),
                        coordsA = coordsS.split(','),
                        coordsPercent = Array.apply(null, Array(coordsA.length));

                    coordsPercent.forEach(function (val, i) {
                        coordsPercent[i] = i % 2 === 0 ? Number(((coordsA[i] / w) * 100) * wPercent) : Number(((coordsA[i] / h) * 100) * hPercent);
                    });
                    area.setAttribute(c, coordsPercent.toString());
                });
            });
            newImg.setAttribute('src', img.getAttribute('src'));
        });
    })();

    window.addEventListener('resize', self.update);
  }
  componentDidMount() {
    this.ImageMap('img[usemap]')
  }
  render() {
    return (
      <div className="text-with-map-and-logo-grid" id="shopscottiesmodal">
        <div className="text-with-map-logo-grid-wrapper">

          <div className="text-with-map">
            <div className="text-with-map-text">
              <div className="map-text-title">
                <div className="intro-light font-32 dark-grey-text">Shop Scotties<sup>®</sup> Facial online or in a store near you</div>
              </div>
              <div className="medium-label-span font-38">Select Region</div>
            </div>
          </div>

          <div className="map-logo-grid-modal">

            <div className="links-maps_wrapper">
              <div className="area-links">
                <ul className="navigation-list">
                  {this.state.locations.map(location => {
                    return (
                      <li>
                        <a href="#" key={location} className="area-link medium-label w-inline-block" onClick={(e) => this.handleChange(e,location)}>
                          <div className={"area-link-text medium-label " + (this.state.location === location ? 'active' : '')}>{location}</div>
                        </a>
                      </li>
                    )
                  })}
                </ul>

              </div>

              <div className="text-with-map-map">
                { this.state.location === 'West' ? <img src={mapwest} useMap="#wtb" alt="" /> : null }
                { this.state.location === 'Midwest' ? <img src={mapmidwest} useMap="#wtb" alt="" /> : null }
                { this.state.location === 'Southeast' ? <img src={mapsoutheast} useMap="#wtb" alt="" /> : null }
                { this.state.location === 'Northeast' ? <img src={mapnortheast} useMap="#wtb" alt="" /> : null }
                { this.state.location === 'Mid-Atlantic' ? <img src={mapmidatlantic} useMap="#wtb" alt="" /> : null }
                <map name="wtb">
                  <area shape="poly" coords="31, 6, 2, 102, 43, 180, 14, 243, 19, 314, 233, 304, 127, 203, 157, 207, 163, 156, 171, 157, 172, 116, 158, 117, 166, 34" alt="West" onClick={(e) => this.handleChange(e,'West')} />
                  <area shape="poly" coords="171, 34, 162, 112, 177, 115, 175, 156, 228, 158, 231, 168, 338, 152, 345, 116, 328, 103, 308, 60, 234, 35" alt="Mid West" onClick={(e) => this.handleChange(e,'Midwest')} />
                  <area shape="poly" coords="316, 159, 327, 191, 321, 211, 301, 214, 357, 269, 364, 252, 346, 211, 385, 147" alt="Southeast" onClick={(e) => this.handleChange(e,'Southeast')} />
                  <area shape="poly" coords="390, 123, 348, 122, 343, 152, 390, 144" alt="Mid Atlantic" onClick={(e) => this.handleChange(e,'Mid-Atlantic')} />
                  <area shape="poly" coords="408, 25, 428, 49, 416, 88, 389, 120, 352, 119, 335, 101" alt="Northeast" onClick={(e) => this.handleChange(e,'Northeast')} />
                </map>
              </div>
            </div>

            <div className="logo-grid">
            {this.props.retailers.filter((el) => {
                return el.node.relationships.field_state.name === this.state.location
              }).map(retailer => {
                return (<div key={retailer.node.id} className="logo-grid-item w-inline-block"><img src={retailer.node.relationships.field_image.localFile.childImageSharp.resolutions.src} alt={retailer.node.title} className="logo-image" /></div>)
              })}

            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default FindRetailer
