/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import PropTypes from "prop-types"

const StyledButton = styled.button`
    background-color: #E71F2B;
    padding: 15px 50px;
    width: ${props => props.fullWidth ? '100%':'auto'};
    text-align: center;
    border: 0;
    font-family: 'MuseoSans-500', arial;
    margin: 25px 0 0 0;
    font-size: 12pt;
    cursor:pointer;
    outline: 0;
    border: 2px solid #E71F2B;
    transition: 0.2s;
    color:  ${props => props.outline ? '#039365':'white'};
    opacity: ${props => props.disabled ? '0.3':'1'};
    :hover {
      border: 2px solid white;
    }
    @media(max-width: 961px){
        max-width: 100%;
        margin: 25px 0% 45px;
    }
    span {
        color:  ${props => props.outline ? '#039365':'white !important'};
        font-size: 16pt !important;
    }
`

const Button = ({children, action, outline, disabled,fullWidth}) => (
    <>
        <StyledButton
            onClick={action}
            outline={outline}
            disabled={disabled}
            fullWidth={fullWidth}
        >{children}
        </StyledButton>
    </>
)

Button.propTypes = {
  action:PropTypes.func,
  children:PropTypes.string
}

export default Button
